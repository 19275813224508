<template>
  <v-card outlined elevation="0" class="d-flex flex-column" min-height="80vh">
    <v-card-title class="text-h5 font-weight-medium">
      <v-btn class="px-n3" color="#122333" to="/jobs">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="ml-5">Create New Job</div>
      <br/>
      <!-- <div>
        <v-alert dense outlined type="error" v-if="user && !isEmailVerified">
          <span class="font-weight-light text-color-white"> <a href="#" @click.prevent="sendVerifyEmail">Please verify your email before creating job</a></span>
        </v-alert>
      </div> -->
      <div>
        <v-alert dense outlined type="error" v-if="!isCompanyNameSet">
          <span class="font-weight-light text-color-white"> <a href="/settings">Please first set compnay Name from Settings</a></span>
        </v-alert>
      </div>
    </v-card-title>
    <v-stepper v-model="pos" elevation="0">
      <v-stepper-header>
        <v-stepper-step :complete="pos > 1" step="1">
          Basic Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 2" step="2">
          Select Skills
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 3" step="3">
          Salary and Benefits
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Final Preview </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- basic details -->
        <v-stepper-content step="1">
          <v-form
            v-model="basicStep"
            class="basic pa-5"
            ref="basicDetails"
            lazy-validation
          >
            <v-row>
              <v-col cols="8">
                <label for="role" class="font-weight-bold text-h6">
                  Job Role
                </label>
                <v-text-field
                  v-model="jobRole"
                  id="role"
                  outlined
                  placeholder="Title of Role"
                  :rules="[(v) => !!v || 'Required']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <label for="location" class="font-weight-bold text-h6">
                  Location
                </label>
                <v-combobox
                  v-model="location"
                  :items="[]"
                  chips
                  small-chips
                  multiple
                  outlined
                  placeholder="Enter location"
                  :rules="[(v) => v.length > 0 || 'Required']"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="jobType" class="font-weight-bold text-h6">
                  Job Type
                </label>
                <v-select
                  v-model="jobType"
                  :items="jobTypes"
                  item-value="value"
                  item-text="name"
                  placeholder="Select Job Type"
                  :rules="[(v) => !!v || 'Required']"
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <label for="experience" class="font-weight-bold text-h6">
                  Experience
                </label>
                <v-text-field
                  v-model="rawExpTxt"
                  placeholder="Eg., 1-3"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => expFormatValid || 'Invalid Format',
                  ]"
                  @input="parseExperience"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <label for="preference" class="font-weight-bold text-h6">
                  Job Preference
                </label>
                <v-combobox
                  v-model="preference"
                  :items="jobPrefTypes"
                  chips
                  small-chips
                  multiple
                  item-value="value"
                  item-text="name"
                  placeholder="Select Job Preference"
                  :rules="[(v) => !!v || 'Required']"
                  outlined
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label for="description" class="font-weight-bold text-h6">
                  Description
                </label>
                <v-textarea
                  v-model="description"
                  outlined
                  placeholder="Job Description"
                  :rules="[(v) => !!v || 'Required']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-card-text>
              <v-row>
                <div class="text-h6 font-weight-bold">Selected Skills</div>
              </v-row>
              <v-row>
                <v-chip-group column>
                  <v-chip
                    v-for="(sel, idx) in selectedSkills"
                    :key="idx"
                    color="success"
                    close
                    @click:close="removeSelectedSkill(idx)"
                  >
                    {{ sel }}
                  </v-chip>
                </v-chip-group>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="newSkill"
                    @keyup.enter="addCustomSkill"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn text @click="addCustomSkill" color="primary" outlined>
                    <v-icon left>
                      {{ mdiPlus }}
                    </v-icon>
                    <span> Add Skill </span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold">Quick Pick Skills</div>
                <v-chip-group v-model="selectedSkills" column multiple>
                  <v-chip
                    v-for="skill in skills"
                    :key="skill"
                    :value="skill"
                    active-class="success white--text"
                  >
                    {{ skill }}
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form
            v-model="salaryBenefitsForm"
            ref="salaryBenefitsForm"
            class="pa-5"
          >
            <v-row>
              <v-col class="pb-0 mb-0">
                <label for="currency" class="text-h6 font-weight-bold">
                  Salary Range
                  <span class="text-body-2 grey--text">per month</span>
                </label>
              </v-col>
            </v-row>
            <v-row>
              <!-- salary -->
              <v-col cols="3">
                <v-select
                  v-model="currency"
                  outlined
                  :items="['INR', 'USD', 'GBP', 'EURO']"
                  placeholder="Currency"
                  dense
                  :rules="[(v) => !!v || 'Required']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <div class="d-flex flex-row">
                  <v-text-field
                    outlined
                    placeholder="Min. Salary"
                    v-model="minSalary"
                    dense
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field>
                  <span class="mt-2 px-2"> to </span>
                  <v-text-field
                    outlined
                    placeholder="Max. Salary"
                    v-model="maxSalary"
                    dense
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <!-- Benefits -->
            <v-row justify="center" align="center">
              <v-col class="d-flex flex-row">
                <label for="Benefits" class="text-h6 font-weight-bold">
                  Benefits
                </label>
                <v-btn color="grey" @click="benefits = defaultBenefits" icon>
                  <v-icon>
                    {{ mdiRestore }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row flex-wrap">
              <v-checkbox
                v-for="(benefit, idx) in benefits"
                :key="idx"
                :label="benefit"
                :value="benefit"
                v-model="benefits"
                hide-details
                class="px-3"
              >
              </v-checkbox>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="newBenefit"
                  @keyup.enter="addBenefit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn text @click="addBenefit" color="primary" outlined>
                  <v-icon left>
                    {{ mdiPlus }}
                  </v-icon>
                  <span> Add Benefit </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <!-- Equity -->
              <v-col cols="6">
                <label for="currency" class="text-h6 font-weight-bold">
                  Equity
                </label>
                <div class="d-flex flex-row pt-3">
                  <v-text-field
                    outlined
                    placeholder="Min. Equity %"
                    dense
                    :rules="[(v) => !!v || 'Required']"
                    v-model="minEquityPercent"
                  ></v-text-field>
                  <span class="mt-2 px-2"> to </span>
                  <v-text-field
                    outlined
                    placeholder="Max. Equity %"
                    dense
                    :rules="[(v) => !!v || 'Required']"
                    v-model="maxEquityPercent"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label for="expiry-picker" class="text-h6 font-weight-bold">
                  Expiry Date
                </label>
                <div>
                  <v-date-picker
                    id="expiry-picker"
                    :allowed-dates="allowedDatesForLink"
                    v-model="expiryEpoch"
                    elevation="1"
                  >
                  </v-date-picker>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card>
            <v-card-text class="text-body-1 black--text">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold"> Job Role </v-col>
                    <v-col cols="6">{{ jobRole }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold"> Location </v-col>
                    <v-col cols="6">{{ location.join(", ") }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold"> Job Type </v-col>
                    <v-col cols="6">{{ jobType }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Experience
                    </v-col>
                    <v-col cols="6">{{ minExp }}-{{ maxExp }} years</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Salary Range
                    </v-col>
                    <v-col cols="6">
                      {{ currency }} {{ minSalary }} to {{ maxSalary }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold"> Equity </v-col>
                    <v-col cols="6">
                      {{ parseFloat(minEquityPercent) }}% to
                      {{ parseFloat(maxEquityPercent) }}%
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="font-weight-bold">
                      Description
                    </v-col>
                    <v-col cols="10">
                      {{ description }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="font-weight-bold"> Skills </v-col>
                    <v-col cols="10">
                      <v-chip-group column>
                        <v-chip
                          v-for="(sel, idx) in selectedSkills"
                          :key="idx"
                          color="success"
                        >
                          {{ sel }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Preference
                    </v-col>
                    <v-col cols="8">
                      {{ preference.map((v) => v.value).join(", ") }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="font-weight-bold">
                      Expiry
                    </v-col>
                    <v-col cols="8" v-if="expiryEpoch">
                      {{ new Date(expiryEpoch).toDateString() }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="font-weight-bold"> Benefits </v-col>
                    <v-col cols="10">
                      <ul>
                        <li v-for="benefit in benefits" :key="benefit">
                          {{ benefit }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-spacer></v-spacer>
    <v-card-actions class="justify-space-between pb-16">
      <v-btn
        class="px-5 py-2 text-none"
        color="gray"
        outlined
        @click="back"
        :disabled="pos === 1"
      >
        Back
      </v-btn>
      <v-btn
        @click="next"
        color="success"
        class="text-none text-subtitle-1 px-5 py-2"
        :loading="submitted"
        :disabled="pos === 2 && !(selectedSkills.length > 0)"
      >
        {{ pos === 4 ? "Finish" : "Next" }}
      </v-btn>
    </v-card-actions>
    <div>
      <go-premium ref="unlock" imageUrl="images/GoPremium.png"> </go-premium>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  mdiPlus,
  mdiMinus,
  mdiTimer,
  mdiRestore,
  mdiInformation,
  mdiArrowLeft,
} from "@mdi/js";
import { mdiLockOutline } from "@mdi/js";
import SkillModal from "../../components/recruiter/SkillModal.vue";
import GoPremium from "../../components/assessment/GoPremium.vue";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";
export default {
  components: { SkillModal, GoPremium },
  data() {
    return {
      pos: 1,
      jobRole: "",
      description: "",
      location: [],
      preference: [],
      jobType: "",
      rawExpTxt: "",
      currency: "",
      defaultBenefits: ["Unlimited PTO", "Device Budget"],
      benefits: [],
      newBenefit: "",
      newSkill: "",
      minExp: null,
      maxExp: null,
      minEquityPercent: null,
      maxEquityPercent: null,
      minSalary: null,
      maxSalary: null,
      jobTypes: [
        {
          name: "Full Time",
          value: "FULL_TIME",
        },
        {
          name: "Internship",
          value: "INTERNSHIP",
        },
        {
          name: "Contract",
          value: "CONTRACT",
        },
      ],
      jobPrefTypes: [
        {
          name: "Remote",
          value: "REMOTE",
        },
        {
          name: "On-Site",
          value: "ONSITE",
        },
        {
          name: "Hybrid",
          value: "HYBRID",
        },
      ],
      skills: ["HTML", "CSS", "JavaScript","Vue.js (with Vuex)", "Vuetify", "Basic GRPC"],
      selectedSkills: [],
      basicStep: true,
      salaryBenefitsForm: false,
      mdiPlus,
      mdiMinus,
      mdiTimer,
      mdiInformation,
      mdiArrowLeft,
      mdiRestore,
      mdiLockOutline,
      submitted: false,
      expiryEpoch: null,
      isCompanyNameSet: false,
    };
  },
  mixins: [formattedTimeMixin],
  methods: {
    ...mapActions("jobs", ["createJob", "updateJob", "fetchJobSkills"]),
    ...mapActions("user", ["sendVerificationEmail"]),
    addBenefit() {
      this.benefits.push(this.newBenefit);
      this.newBenefit = "";
    },
    addCustomSkill() {
      this.selectedSkills.push(this.newSkill);
      this.newSkill = "";
    },
    removeSelectedSkill(del) {
      console.log(del, this.selectedSkills[del]);
      this.selectedSkills.splice(del, 1);
      this.selectedSkills = [...this.selectedSkills];
    },
    submit() {
      this.submitted = true;
      this.createJob({
        title: this.jobRole,
      }).then(() => {
        this.updateJob({
          about: this.description,
          skills: this.selectedSkills,
          role: this.jobRole,
          preference: this.preference.map((v) => v.value),
          minCtc: this.minSalary,
          maxCtc: this.maxSalary,
          minEquity: this.minEquityPercent,
          maxEquity: this.maxEquityPercent,
          benefitsList: this.benefits,
          currency: this.currency,
          jobType: this.jobType,
          minExp: this.minExp,
          maxExp: this.maxExp,
          locationList: this.location,
          expiry: this.expiryEpoch,
        }).then(() => {
          //fix this, contentView is not returning url from backend in builder update content call
          console.log("JOB...", this.jobView)
          this.$router.push(`/jobs/${this.jobView?.getUrl()}`);
        });
      });
    },
    parseExperience() {
      if (this.expFormatValid) {
        if (this.rawExpTxt?.includes("-")) {
          [this.minExp, this.maxExp] = this.rawExpTxt
            .split("-")
            .map((v) => !!v && parseInt(v));
        } else {
          this.minExp = parseInt(this.rawExpTxt);
        }
      }
      console.log(this.minExp, this.maxExp);
    },
    back() {
      if (this.pos !== 1) {
        this.pos = this.pos - 1;
      } else {
        this.pos = 1;
      }
    },
    next() {
      if (this.pos === 1) {
        if (this.$refs.basicDetails.validate()) {
          console.log({
            role: this.jobRole,
            location: this.location,
            type: this.jobType,
            pref: this.preference,
            desc: this.description,
          });
          this.pos = 2;
        }
      } else if (
        this.pos === 2 ||
        (this.pos === 3 && this.$refs.salaryBenefitsForm.validate())
      ) {
        this.pos += 1;
      } else if (this.pos === 4) {
        // console.log(`submit stuff`);
        this.submit();
      } else {
        // console.log("out of bounds");
      }
    },
    allowedDatesForLink(selection) {
      // yyyy-mm-dd is the internal rep of date in v-date-picker
      // allowed date should be greater than today's date
      const selectedDate = new Date(selection);
      const today = new Date();
      return selectedDate > today;
    },
    // sendVerifyEmail() {
		// 	this.sendVerificationEmail().then(__ => alert("Verification email sent"))
		// 	.catch(ex => {
		// 		alert("Could not send verification email. Please try again.");
		// 		console.log("Error while sending verification mail", ex);
		// 	})
		// },
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("jobs", ["jobSkills", "jobView", "url"]),
    ...mapGetters("customer", ["hiringPlanKeys", "prettyHiringPlans"]),
    // ...mapState("user", ["user"]),
    // isEmailVerified() {
		// 	console.log("Is Email verified ", this.user && this.user.emailVerified);
		// 	return this.user && this.user.emailVerified;
		// },
    expFormatValid() {
      this.rawExpTxt = this.rawExpTxt.trim();
      // https://stackoverflow.com/a/32311188
      // symbols regex (only without "-" symbol)
      if (
        this.rawExpTxt.startsWith("-") ||
        /[a-zA-Z]/g.test(this.rawExpTxt) ||
        /[ `!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/.test(this.rawExpTxt) ||
        Number.isNaN(parseInt(this.rawExpTxt[0]))
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.benefits = this.defaultBenefits;
    this.fetchJobSkills().then(() => {
      console.log(this.jobSkills[0].toObject());
      this.skills = this.jobSkills.map((v) => v.getSkill());
    });
    if(this.customer) {
			console.log("Watching customer,,,", this.customer);
      this.isCompanyNameSet = this.customer.getName().length > 0;
    }
  },
  watch: {
    customer(newCustomer) {
      if(!newCustomer) return;
			console.log("Watching customer,,,", newCustomer);
      this.isCompanyNameSet = newCustomer.getName().length > 0;
    }
  },
  watch: {
    customer(newCustomer) {
      if(!newCustomer) return;
			console.log("Watching customer,,,", newCustomer);
      this.isCompanyNameSet = newCustomer.getName().length > 0;
    }
  },
  watch: {
    customer(newCustomer) {
      if(!newCustomer) return;
			console.log("Watching customer,,,", newCustomer);
      this.isCompanyNameSet = newCustomer.getName().length > 0;
    }
  },
};
</script>
<style scoped>
.v-stepper__header {
  box-shadow: none;
}
.skill-description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #333333;
}
</style>
