var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "min-height": "80vh"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-medium"
  }, [_c('v-btn', {
    staticClass: "px-n3",
    attrs: {
      "color": "#122333",
      "to": "/jobs"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v("Create New Job")]), _c('br'), _c('div', [!_vm.isCompanyNameSet ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error"
    }
  }, [_c('span', {
    staticClass: "font-weight-light text-color-white"
  }, [_c('a', {
    attrs: {
      "href": "/settings"
    }
  }, [_vm._v("Please first set compnay Name from Settings")])])]) : _vm._e()], 1)], 1), _c('v-stepper', {
    attrs: {
      "elevation": "0"
    },
    model: {
      value: _vm.pos,
      callback: function callback($$v) {
        _vm.pos = $$v;
      },
      expression: "pos"
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 1,
      "step": "1"
    }
  }, [_vm._v(" Basic Details ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 2,
      "step": "2"
    }
  }, [_vm._v(" Select Skills ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 3,
      "step": "3"
    }
  }, [_vm._v(" Salary and Benefits ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "4"
    }
  }, [_vm._v(" Final Preview ")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('v-form', {
    ref: "basicDetails",
    staticClass: "basic pa-5",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.basicStep,
      callback: function callback($$v) {
        _vm.basicStep = $$v;
      },
      expression: "basicStep"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "role"
    }
  }, [_vm._v(" Job Role ")]), _c('v-text-field', {
    attrs: {
      "id": "role",
      "outlined": "",
      "placeholder": "Title of Role",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.jobRole,
      callback: function callback($$v) {
        _vm.jobRole = $$v;
      },
      expression: "jobRole"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "location"
    }
  }, [_vm._v(" Location ")]), _c('v-combobox', {
    attrs: {
      "items": [],
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "outlined": "",
      "placeholder": "Enter location",
      "rules": [function (v) {
        return v.length > 0 || 'Required';
      }]
    },
    model: {
      value: _vm.location,
      callback: function callback($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "jobType"
    }
  }, [_vm._v(" Job Type ")]), _c('v-select', {
    attrs: {
      "items": _vm.jobTypes,
      "item-value": "value",
      "item-text": "name",
      "placeholder": "Select Job Type",
      "rules": [function (v) {
        return !!v || 'Required';
      }],
      "outlined": ""
    },
    model: {
      value: _vm.jobType,
      callback: function callback($$v) {
        _vm.jobType = $$v;
      },
      expression: "jobType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "experience"
    }
  }, [_vm._v(" Experience ")]), _c('v-text-field', {
    attrs: {
      "placeholder": "Eg., 1-3",
      "rules": [function (v) {
        return !!v || 'Required';
      }, function (v) {
        return _vm.expFormatValid || 'Invalid Format';
      }],
      "outlined": ""
    },
    on: {
      "input": _vm.parseExperience
    },
    model: {
      value: _vm.rawExpTxt,
      callback: function callback($$v) {
        _vm.rawExpTxt = $$v;
      },
      expression: "rawExpTxt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "preference"
    }
  }, [_vm._v(" Job Preference ")]), _c('v-combobox', {
    attrs: {
      "items": _vm.jobPrefTypes,
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "item-value": "value",
      "item-text": "name",
      "placeholder": "Select Job Preference",
      "rules": [function (v) {
        return !!v || 'Required';
      }],
      "outlined": ""
    },
    model: {
      value: _vm.preference,
      callback: function callback($$v) {
        _vm.preference = $$v;
      },
      expression: "preference"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(" Description ")]), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "placeholder": "Job Description",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('v-row', [_c('div', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v("Selected Skills")])]), _c('v-row', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.selectedSkills, function (sel, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success",
        "close": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.removeSelectedSkill(idx);
        }
      }
    }, [_vm._v(" " + _vm._s(sel) + " ")]);
  }), 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addCustomSkill.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newSkill,
      callback: function callback($$v) {
        _vm.newSkill = $$v;
      },
      expression: "newSkill"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.addCustomSkill
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _c('span', [_vm._v(" Add Skill ")])], 1)], 1)], 1), _c('v-row', [_c('div', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v("Quick Pick Skills")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.selectedSkills,
      callback: function callback($$v) {
        _vm.selectedSkills = $$v;
      },
      expression: "selectedSkills"
    }
  }, _vm._l(_vm.skills, function (skill) {
    return _c('v-chip', {
      key: skill,
      attrs: {
        "value": skill,
        "active-class": "success white--text"
      }
    }, [_vm._v(" " + _vm._s(skill) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_c('v-form', {
    ref: "salaryBenefitsForm",
    staticClass: "pa-5",
    model: {
      value: _vm.salaryBenefitsForm,
      callback: function callback($$v) {
        _vm.salaryBenefitsForm = $$v;
      },
      expression: "salaryBenefitsForm"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0 mb-0"
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "currency"
    }
  }, [_vm._v(" Salary Range "), _c('span', {
    staticClass: "text-body-2 grey--text"
  }, [_vm._v("per month")])])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": ['INR', 'USD', 'GBP', 'EURO'],
      "placeholder": "Currency",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.currency,
      callback: function callback($$v) {
        _vm.currency = $$v;
      },
      expression: "currency"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Min. Salary",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.minSalary,
      callback: function callback($$v) {
        _vm.minSalary = $$v;
      },
      expression: "minSalary"
    }
  }), _c('span', {
    staticClass: "mt-2 px-2"
  }, [_vm._v(" to ")]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Max. Salary",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.maxSalary,
      callback: function callback($$v) {
        _vm.maxSalary = $$v;
      },
      expression: "maxSalary"
    }
  })], 1)])], 1), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-row"
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "Benefits"
    }
  }, [_vm._v(" Benefits ")]), _c('v-btn', {
    attrs: {
      "color": "grey",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.benefits = _vm.defaultBenefits;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiRestore) + " ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "d-flex flex-row flex-wrap"
  }, _vm._l(_vm.benefits, function (benefit, idx) {
    return _c('v-checkbox', {
      key: idx,
      staticClass: "px-3",
      attrs: {
        "label": benefit,
        "value": benefit,
        "hide-details": ""
      },
      model: {
        value: _vm.benefits,
        callback: function callback($$v) {
          _vm.benefits = $$v;
        },
        expression: "benefits"
      }
    });
  }), 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addBenefit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newBenefit,
      callback: function callback($$v) {
        _vm.newBenefit = $$v;
      },
      expression: "newBenefit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.addBenefit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _c('span', [_vm._v(" Add Benefit ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "currency"
    }
  }, [_vm._v(" Equity ")]), _c('div', {
    staticClass: "d-flex flex-row pt-3"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Min. Equity %",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.minEquityPercent,
      callback: function callback($$v) {
        _vm.minEquityPercent = $$v;
      },
      expression: "minEquityPercent"
    }
  }), _c('span', {
    staticClass: "mt-2 px-2"
  }, [_vm._v(" to ")]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Max. Equity %",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.maxEquityPercent,
      callback: function callback($$v) {
        _vm.maxEquityPercent = $$v;
      },
      expression: "maxEquityPercent"
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "expiry-picker"
    }
  }, [_vm._v(" Expiry Date ")]), _c('div', [_c('v-date-picker', {
    attrs: {
      "id": "expiry-picker",
      "allowed-dates": _vm.allowedDatesForLink,
      "elevation": "1"
    },
    model: {
      value: _vm.expiryEpoch,
      callback: function callback($$v) {
        _vm.expiryEpoch = $$v;
      },
      expression: "expiryEpoch"
    }
  })], 1)])], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "text-body-1 black--text"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Job Role ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s(_vm.jobRole))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Location ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s(_vm.location.join(", ")))])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Job Type ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s(_vm.jobType))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Experience ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s(_vm.minExp) + "-" + _vm._s(_vm.maxExp) + " years")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Salary Range ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.currency) + " " + _vm._s(_vm.minSalary) + " to " + _vm._s(_vm.maxSalary) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Equity ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.minEquityPercent)) + "% to " + _vm._s(parseFloat(_vm.maxEquityPercent)) + "% ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Description ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Skills ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.selectedSkills, function (sel, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success"
      }
    }, [_vm._v(" " + _vm._s(sel) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Preference ")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.preference.map(function (v) {
    return v.value;
  }).join(", ")) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Expiry ")]), _vm.expiryEpoch ? _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(new Date(_vm.expiryEpoch).toDateString()) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Benefits ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('ul', _vm._l(_vm.benefits, function (benefit) {
    return _c('li', {
      key: benefit
    }, [_vm._v(" " + _vm._s(benefit) + " ")]);
  }), 0)])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-spacer'), _c('v-card-actions', {
    staticClass: "justify-space-between pb-16"
  }, [_c('v-btn', {
    staticClass: "px-5 py-2 text-none",
    attrs: {
      "color": "gray",
      "outlined": "",
      "disabled": _vm.pos === 1
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Back ")]), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 px-5 py-2",
    attrs: {
      "color": "success",
      "loading": _vm.submitted,
      "disabled": _vm.pos === 2 && !(_vm.selectedSkills.length > 0)
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.pos === 4 ? "Finish" : "Next") + " ")])], 1), _c('div', [_c('go-premium', {
    ref: "unlock",
    attrs: {
      "imageUrl": "images/GoPremium.png"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }